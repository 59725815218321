import Text from './Text'
import Button from './Button';

const InactiveView = ({ data, loading, onStart }) => {
    return (
        <>
            <Text>Press {data.isBreak ? 'CONTINUE' : 'START'} to start time tracking</Text>
            <Button onClick={() => {onStart()}}>{loading ? 'LOADING...' : (data.isBreak ? 'Continue' : 'Start')}</Button>
        </>
    )
}

export default InactiveView;