import { styled } from "styled-components";

const Button = styled.button`
width: 100%;
padding: 10px;
font-size:20px;
text-align:center;
border-radius: 5px;
font-weight: bold;
box-sizing: border-box;
margin-top: 20px;
text-transform: uppercase;
`

const MyText = (props) => {
    return (<Button {...props} />)
}

export default MyText;