import axios from "axios";

class API {
  constructor(baseURL) {
    this.code = null;

    this.axios = axios.create({
      baseURL: baseURL,
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        console.log("rofl");
        console.log(error);

        if (error.response.status === 401) {
          // setError("The authorization code is not valid!");
          // axios.defaults.headers.code = null;
          // localStorage.removeItem("code");
        } else {
          // setError(error.response.data.message);
        }

        return Promise.reject(error);
      }
    );
  }

  async getStatus(code) {
    try {
      const result = await this.axios.get("/", {
        headers: {
          code,
        },
      });

      console.log("statussss", result.status);

      if (result.status !== 200) {
        return false;
      }

      // axios.defaults.headers.code = code;

      this.code = code;

      return result.data;
    } catch (e) {
      if (e.response.status !== 401) {
        console.log("code seems correct so save it");
        this.code = code;
        throw e;
      }

      console.log(e);
    }
  }

  async startTime(position) {
    try {
      // console.log(position);

      const result = await this.axios.post(
        "/",
        {
          action: "start",
          coordinates: position
            ? [position.coords.longitude, position.coords.latitude]
            : null,
        },
        {
          headers: {
            code: this.code,
          },
        }
      );

      if (result.status !== 201) {
        return false;
      }

      return result.data;
    } catch (e) {
      console.log(e);
    }
  }

  async startBreak(position) {
    try {
      // console.log(position);

      const result = await this.axios.post(
        "/",
        {
          action: "break",
          coordinates: position
            ? [position.coords.longitude, position.coords.latitude]
            : null,
        },
        {
          headers: {
            code: this.code,
          },
        }
      );

      if (result.status !== 201) {
        return false;
      }

      return result.data;
    } catch (e) {
      console.log(e);
    }
  }

  async stopTime(position) {
    try {
      // console.log(position);

      const result = await this.axios.post(
        "/",
        {
          action: "stop",
          coordinates: position
            ? [position.coords.longitude, position.coords.latitude]
            : null,
        },
        {
          headers: {
            code: this.code,
          },
        }
      );

      if (result.status !== 201) {
        return false;
      }

      return result.data;
    } catch (e) {
      console.log(e);
    }
  }
}

export default API;
