import { useState } from 'react';
import { styled } from 'styled-components';

import Title from './Title';
import Text from './Text'

import InactiveView from './InactiveView';
import ActiveView from './ActiveView';

const Wrapper = styled.div``

const getPosition = (options) => {
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}

const Authorized = ({ loading, data, onStart, onStop, onBreak }) => {
    const [lat, setLat] = useState('');
    const [active, setActive] = useState(false);

    /*
    const handleStart = async () => {
        try {
            const position = await getPosition();
            console.log(position);
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            // alert(position.coords.longitude);
            setLat(position.coords.latitude)
        } catch (e) {
            console.log('failed to get position', e);
            setLat(e.message);
        }

        setActive(true);
    }
    */

    if(data.noCustomer) {
        return (
            <Wrapper>
                <Title>Welcome</Title>
                <Text>Welcome back {data.firstname} {data.lastname}</Text>
                <Text error>You are not assigned to a customer!</Text>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Title>Welcome</Title>
            <Text>Welcome back {data.firstname} {data.lastname}</Text>
            {(!data.startDate || data.isBreak) && <InactiveView data={data} loading={loading} onStart={onStart} />}
            {data.startDate && <ActiveView loading={loading} data={data} onStop={onStop} onBreak={onBreak} />}
        </Wrapper>
    )
}

export default Authorized;