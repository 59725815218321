import { styled } from "styled-components";

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #fff;
`

const MyText = (props) => {
    return (<Title {...props} />)
}

export default MyText;