import Text from './Text'
import Button from './Button';

const ActiveView = ({ loading, data, onStop, onBreak }) => {
    return (
        <>
            <Text>You started {data.isBreak ? 'your break' : 'time tracking'} on: {new Date(data.startDate).toLocaleString()}</Text>
            {/* <Button onClick={() => {onStop()}}>UPLOAD PICTURE AND STOP</Button> */}
            {!data.isBreak && <Button onClick={() => {onBreak()}}>{loading ? 'LOADING...' : 'BREAK'}</Button>}
            <Button onClick={() => {onStop()}}>{loading ? 'LOADING...' : 'STOP'}</Button>
            {/*
            <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: 'none' }}
            />
            <label htmlFor="select-image">
                
                    Upload Image
                
            </label>
            */}
        </>
    )
}

export default ActiveView;