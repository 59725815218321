import { styled } from "styled-components";

const Text = styled.p`
  color: ${props => props.error ? '#ff0000' : '#fff'};
`

const MyText = (props) => {
    return (<Text {...props} />)
}

export default MyText;