import { styled } from "styled-components";

const Input = styled.input`
width: 100%;
padding: 10px;
font-size:20px;
text-align:center;
border-radius: 5px;
font-weight: bold;
box-sizing: border-box;
text-transform: uppercase;
`

const MyText = (props) => {
    return (<Input {...props} />)
}

export default MyText;