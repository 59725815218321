import { useEffect, useState } from "react";
import { styled } from "styled-components";

import logo from "./assets/logo.svg";
import Authorization from "./components/Authorization";
import Authorized from "./components/Authorized";
import Text from "./components/Text";

import API from "./API";

const { REACT_APP_API } = process.env;

const api = new API(REACT_APP_API);

const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 50px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
`;

const LogoWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 50px;
`;

const Footer = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
`;

const getPosition = (options) => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
};

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleStart = async () => {
    setIsLoading(true);
    let position = null;

    try {
      position = await getPosition({
        timeout: 5000,
        maximumAge: 10000,
        enableHighAccuracy: true,
      });
    } catch (e) {
      console.log(e);
    }

    try {
      const result = await api.startTime(position);

      setIsLoading(false);
      getStatus();
    } catch (e) {
      console.log(e);
      setError(e.response.data.message);
    }
  };

  const handleStop = async () => {
    setIsLoading(true);
    let position = null;

    try {
      position = await getPosition({
        timeout: 5000,
        maximumAge: 10000,
        enableHighAccuracy: true,
      });
    } catch (e) {
      console.log(e);
    }

    try {
      const result = await api.stopTime(position);

      setIsLoading(false);
      getStatus();

      /*
      if (result.status !== 201) {
        return false;
      }
      */

      // return result.data;
    } catch (e) {
      console.log(e);
      setError(e.response.data.message);
    }
  };

  const handleBreak = async () => {
    setIsLoading(true);
    let position = null;

    try {
      position = await getPosition({
        timeout: 5000,
        maximumAge: 10000,
        enableHighAccuracy: true,
      });
    } catch (e) {
      console.log(e);
    }

    try {
      const result = await api.startBreak(position);

      setIsLoading(false);
      getStatus();

      /*
      if (result.status !== 201) {
        return false;
      }
      */

      // return result.data;
    } catch (e) {
      console.log(e);
      setError(e.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("code");
    setIsAuthorized(false);
    setData(null);
  };

  const getStatus = async () => {
    const savedCode = localStorage.getItem("code");

    if (!savedCode) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const status = await api.getStatus(savedCode);

      console.log(status);
      setError(null);

      if (status) {
        setIsAuthorized(true);
        setData(status);
      }
    } catch (e) {
      console.log(e);
      setError(e.response.data.message);
    }

    setIsLoading(false);
  };

  const handleAuthorization = (code) => {
    localStorage.setItem("code", code);

    getStatus();
  };

  useEffect(() => {
    setIsAuthorized(false);
    setData(null);
    setError(null);
    getStatus();
    // api.getStatus("123456");
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <LogoWrapper>
          <Logo src={logo} alt="Work is Done" />
        </LogoWrapper>
        <ContentWrapper>Loading. Please wait...</ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo src={logo} alt="Work is Done" />
      </LogoWrapper>
      <ContentWrapper>
        {!isAuthorized && (
          <Authorization onSubmit={handleAuthorization} error={error} />
        )}
        {isAuthorized && (
          <Authorized
            loading={isLoading}
            data={data}
            onStart={handleStart}
            onStop={handleStop}
            onBreak={handleBreak}
          />
        )}
      </ContentWrapper>
      <Footer>
        {isAuthorized && (
          <Link
            href="#"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Link>
        )}
        <br />
        <br />
        <small>V1.1</small>
      </Footer>
    </Wrapper>
  );
}

export default App;
