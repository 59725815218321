import { useState } from "react";
import { styled } from "styled-components";

import Title from "./Title";
import Text from "./Text";
import Input from "./Input";
import Button from "./Button";

const Wrapper = styled.div``;

const Authorization = ({ onSubmit, error }) => {
  const [code, setCode] = useState(localStorage.getItem("code") || "");

  return (
    <Wrapper>
      <Title>Login</Title>
      <Text>Please enter your authorization code</Text>
      {error && <Text error>{error}</Text>}
      <Input
        placeholder="Authorization Code"
        value={code}
        onChange={(e) => {
          setCode(e.target.value.toUpperCase());
        }}
      ></Input>
      <Button
        onClick={() => {
          onSubmit(code);
        }}
      >
        Login
      </Button>
    </Wrapper>
  );
};

export default Authorization;
